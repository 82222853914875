import React from 'react';
import './App.scss';
// import useMousePosition from './components/Home/mouseanimation'; // Ensure the correct path
import Routess from './Routes/Route';
import UseMousePosition from './componnets/Home/mouseanimation';

function App() {
  const { x, y } = UseMousePosition();

  return (
    <div className="App">
      {/* Your routed components */}
      <Routess/>

      {/* Mouse animation element */}
      <div
        className="mouse-animation"
        style={{
          transform: `translate(${x}px, ${y}px)`,
        }}
      />
    </div>
  );
}

export default App;

