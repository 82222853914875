import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import "./Nav.scss"
export default function Navbar() {
  return (
    <div>
      <nav>
        <div className="container">
         <div className="nav_wrp">
          <div className="logo">
            {/* <img src="" alt="" /> */}
            <h1>Wennax</h1>
          </div>
          <div className="menu">
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/service">Services</NavLink></li>
              <li><NavLink to="/blog">Blog</NavLink></li>

              {/* <li><NavLink to="/">Solutions</NavLink></li> */}
              <li><NavLink to="/about">About us</NavLink></li>
              <li><NavLink to="/contact">Contact us</NavLink></li>


            </ul>
          </div>
          <div className="contact">
          <i class="fa-solid fa-phone-volume"></i>
            <div className="number">
              <p>Any Queastion ? </p>
              <h2>+91 62944 67292</h2>
            </div>
          </div>
         </div>
        </div>
      </nav>
    </div>
  )
}
