import React, { useState, useEffect } from "react";
import "./Home.scss"; // Ensure to import the SCSS for styles
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import Slick styles
import "slick-carousel/slick/slick-theme.css";
const images = [
  "https://img.freepik.com/free-photo/transhumanist-using-neuroscientific-tech-transcend-physical-limitations_482257-88593.jpg?t=st=1722544731~exp=1722548331~hmac=38bca1ecacda2b15c18a8222c97f1bb359f148a043e1a384413653dcbe463d15&w=826",
  "https://img.freepik.com/free-photo/futuristic-scene-with-high-tech-robot-used-construction-industry_23-2151329542.jpg?t=st=1722544681~exp=1722548281~hmac=b5927ee3946ff81b361f5746d5e934804117f7d5c2413544b8a4d274b953a1a5&w=826",
  "https://img.freepik.com/free-photo/programming-background-with-person-working-with-codes-computer_23-2150010125.jpg?t=st=1722543577~exp=1722547177~hmac=4a60a046244209c396bf30ef486b5d24f04dbb4012c576ad402b54b40a7f0272&w=826",
  // Add more image URLs as needed
];

const marqueeTexts = [
  "We believe in the power of collaboration",
  "We invite you to join us on this exciting journey",
  "We thrive on creativity",
  "Welcome to InnovateTech Solutions",
];

const services = [
  {
    title: "Web Development",
    icon: "fa-solid fa-laptop-code",
    description:
      "Build responsive and high-performance websites tailored to your needs.",
    link: "/services/web-development",
  },
  {
    title: "App Development",
    icon: "fa-solid fa-mobile-alt",
    description:
      "Create cutting-edge mobile applications for Android and iOS platforms.",
    link: "/services/app-development",
  },
  {
    title: "Digital Marketing",
    icon: "fa-solid fa-chart-line",
    description:
      "Boost your online presence and reach your target audience effectively.",
    link: "/services/digital-marketing",
  },
  {
    title: "UI/UX Design",
    icon: "fa-solid fa-paint-brush",
    description: "Design user-friendly interfaces and enhance user experience.",
    link: "/services/ui-ux",
  },
  // Add more services if needed
];

export default function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div>
      <section className="banner">
        <div className="container">
          <div className="banner_wrp">
            <div className="banner_info">
              <h6>
                <i class="fa-solid fa-asterisk"></i> Expertise You Can Trust{" "}
                <i class="fa-solid fa-asterisk"></i>
              </h6>
              <h1>
                Empowering your brand <br />
                in the digital universe
              </h1>
              <p>
                Join us as we carve a path to success, driven by passion,
                powered by innovation, and fueled by the collective spirit of
                our team. We believe in the power of bold ideas, and we're here
                to turn them into reality.
              </p>
              <div className="buttons">
                <button>Learn More</button>
              </div>
            </div>
            <div className="banner_images">
              {images.map((src, index) => (
                <img
                  key={src}
                  src={src}
                  alt={`Banner Image ${index + 1}`}
                  className={index === currentIndex ? "active" : ""}
                />
              ))}
              <div className="pay_icon">
                <i className="fa-solid fa-play"></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="marquee_section">
        <div className="container">
          <div className="marquee_container">
            <div className="marquee_text">
              {marqueeTexts.map((text, index) => (
                <div key={index} className="marquee_item">
                  <i className="fa-solid fa-certificate"></i>{" "}
                  {/* Certificate icon */}
                  <span>{text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="services_section">
        <div className="container">
          <div className="heading">
            <h6>
              <i class="fa-solid fa-asterisk"></i> Expertise You Can Trust{" "}
              <i class="fa-solid fa-asterisk"></i>
            </h6>
            <h1>Explore Our Solutions</h1>
          </div>
          <div className="box">
            <Slider {...settings} className="services_slider">
              {services.map((service, index) => (
                <div key={index} className="service_card">
                  <img
                    src="https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150062008.jpg?t=st=1723112182~exp=1723115782~hmac=ea3aaa813f7c6aecf66f300cffa0b28b67540d886a6d16748d83d25b6be630e4&w=826"
                    alt=""
                  />
                  <h2 className="service_title">{service.title}</h2>
                  <p className="service_description">{service.description}</p>
                  <a href={service.link} className="learn_more">
                    Learn More <i class="fa-solid fa-arrow-right"></i>{" "}
                  </a>
                </div>
              ))}
            </Slider>
          </div>
          <div className="snap"></div>
        </div>
      </section>
      <section className="whoWe">
        <div className="container">
          <div className="whoWe_wrp">
            <div className="img">
              <img
                src="https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150062008.jpg?t=st=1723112182~exp=1723115782~hmac=ea3aaa813f7c6aecf66f300cffa0b28b67540d886a6d16748d83d25b6be630e4&w=826"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/young-confused-handsome-caucasian-man-puts-hand-head-holds-laptop-isolated-white-background-with-copy-space_141793-65651.jpg?t=st=1723112783~exp=1723116383~hmac=dea3f3184e5d28cc96f5289e120896ec61e71188885a58b1a6d43c1a204ae0a6&w=740"
                alt=""
              />
            </div>

            <div className="info">
              <div className="heading">
                <h6>
                  <i class="fa-solid fa-asterisk"></i> Who we are {" "}
                  <i class="fa-solid fa-asterisk"></i>
                </h6>
                <h1>Transformative Design, Precision Engineering, and Reliable Support</h1>

              </div>
              <p>Embarrassing hidden in the middle All the Lorem Ipsum generators on the Internet repeat predefined chunks Embarrassing hidden in the middle All the Lorem Ipsum generators on the Internet repeat predefined chunks Embarrassing hidden in the middle All the Lorem Ipsum generators on the Internet repeat predefined chunks Embarrassing hidden in the middle All the Lorem Ipsum generators on the Internet repeat predefined chunks Embarrassing hidden in the middle All the Lorem Ipsum generators on the Internet repeat predefined chunks</p>
              <a>More info </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
