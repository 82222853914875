import React from 'react';
import './Footer.scss'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
      {/* <div className="footer-top">
        <div className="footer-column">
          <h1>Wennax</h1>
          <p className="footer-text">Some text about the company</p>
          <div className="footer-socials">
            <a href="https://facebook.com" aria-label="Facebook">
              <FontAwesomeIcon icon={faFacebook} className="footer-icon" />
            </a>
            <a href="https://twitter.com" aria-label="Twitter">
              <FontAwesomeIcon icon={faTwitter} className="footer-icon" />
            </a>
            <a href="https://instagram.com" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} className="footer-icon" />
            </a>
          </div>
        </div>
        <div className="footer-column">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Contact Us</h4>
          <p>123 Company Address<br />City, State, ZIP</p>
          <p>
            <FontAwesomeIcon icon={faPhone} /> (123) 456-7890<br />
            <FontAwesomeIcon icon={faEnvelope} /> info@company.com
          </p>
        </div>
      </div> */}

      </div>
        <div className="container">
      <div className="footer-bottom">
        <p className="footer-bottom-left">
          <a href="/terms">Terms</a> | <a href="/privacy">Privacy Policy</a>
        </p>
        <p className="footer-bottom-right">&copy; 2024 Wennax. All Rights Reserved.</p>
      </div>
      </div>
    </footer>
  );
}
