import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from '../componnets/About/About';
import Contat from '../componnets/Contact/Contat';
import Nopage from '../componnets/Nopage/Nopage';
import Home from '../componnets/Home/Home';
import Navbar from '../componnets/Navbar/Navbar';
import Footer from '../componnets/Footer/Footer';

export default function Routess() {
  return (
    <div>
        <BrowserRouter>
        <Navbar/>
            <Routes>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contat />} />
                <Route path="*" element={<Nopage />} />
            </Routes>
        <Footer/>    
        </BrowserRouter>
  </div>
  )
}
